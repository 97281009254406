import {Injectable} from '@angular/core';
import {BaseApi} from '../base-api';

@Injectable({
    providedIn: 'root'
})
export class AnimalService extends BaseApi {

    getAnimal = (id: string): Promise<any> => this.get(`/animal/${id}`);

    getByParamsAndPage = (params: object): Promise<any> => this.post('/animal/ByParamsAndPage', params);

    getByObjectAndSpecies = (objectId: string, speciesId: string): Promise<any> => this.get(`/Animal/ByObject/${objectId}/BySpecies/${speciesId}`);

    getByTagNumber = (tag: string): Promise<any> => this.get(`/animal/FindByTag/${tag}`);
    getOwnByTagNumber = (tag: string): Promise<any> => this.get(`/animal/FindOwnByTag/${tag}`);

    addAnimal = (animalData: object): Promise<any> => this.post('/animal', animalData);

    editAnimal = (animalData: object): Promise<any> => this.put('/animal', animalData);

    getAnimalProfile = (id: string): Promise<any> => this.get(`/animal/profile/${id}`);

    getHealthProfile = (id: string): Promise<any> => this.get(`/health/animal/${id}`);

    getMovements = (id: string): Promise<any> => this.get(`/movement/animal/${id}`);

    changeStatus = (data: object): Promise<any> => this.post('/Animal/ChangeStatus', data);
    changeHealthStatus = (data: object): Promise<any> => this.post('/Animal/ChangeHealthStatus', data);
    changeEarTag = (data: object): Promise<any> => this.post('/Animal/ChangeEarTag', data);

    deleteProhibition = (id: string): Promise<any> => this.delete(`/Animal/prohibition/${id}`);

    deregisterTheftOrMissing = (data: object): Promise<any> => this.post('/Animal/DeregisterTheftOrMissing', data);
    deregisterDeathWithBody = (data: object): Promise<any> => this.post('/Animal/DeregisterDeath', data);
    deregisterDeathWithoutBodyWithTag = (data: object): Promise<any> => this.post('/Animal/DeregisterDeathWithTag', data);
    deregisterDeathWithoutBodyWithoutTag = (data: object): Promise<any> => this.post('/Animal/DeregisterDeathWithoutTag', data);
    deregisterDeathDisaster = (data: object): Promise<any> => this.post('/Animal/DeregisterDeathOnNaturalDisaster', data);
    deregisterSlaughterOwnWithTag = (data: object): Promise<any> => this.post('/Animal/DeregisterOwnConsumptionWithTag', data);
    deregisterSlaughterOwnWithoutTag = (data: object): Promise<any> => this.post('/Animal/DeregisterOwnConsumptionWithoutTag', data);
    deregisterSlaughterHouseNonActive = (data: object): Promise<any> => this.post('/Animal/DeregisterSlaughterHouseNonActive', data);
    deregisterExportActive = (data: object): Promise<any> => this.post('/Animal/DeregisterExportActive', data);
    deregisterExportNonActive = (data: object): Promise<any> => this.post('/Animal/DeregisterExportNonActive', data);
    deregisterOwnerSaidSoldNonActive = (data: object): Promise<any> => this.post('/Animal/DeregisterOwnerSaidSoldNonActive', data);

    addCheckup = (data: object): Promise<any> => this.post('/Checkup', data);
    animalCheck = (tagNumber: string, token: string): Promise<any> => this.get(`/Animal/check/${tagNumber}?captchaToken=${token}`);
    animalCheck2 = (tagNumber: string): Promise<any> => this.get(`/Animal/check2/${tagNumber}`);

    exportToExl = (filterData: object): Promise<any> => this.post('/Animal/Export', filterData, {responseType: 'blob'});

    healthStatusHistory = (filterData: object): Promise<any> => {
            let url = '/HealthStatusHistory/ByPage?';
            Object.keys(filterData).forEach(param => {
                if (filterData[param] !== null) {

                    if (Array.isArray(filterData[param])) {
                        filterData[param].forEach(p => {
                            url += `&${param}=${encodeURIComponent(p)}`;
                        });
                    } else {
                        url += `&${param}=${encodeURIComponent(filterData[param])}`;
                    }
                }
            });


        return this.get(url);
    }
}
