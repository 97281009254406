import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BarcodeFormat} from '@zxing/library';
import {TranslateService} from '@ngx-translate/core';
import {AnonymousService} from '../../shared/services/anonymous.service';
import {ReCaptchaV3Service} from 'ngx-captcha';
import {ReCaptchaKey} from '../../shared/const/reCaptchaKey';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {ActivatedRoute, Router} from '@angular/router';
import {ReferenceService} from '../../shared/services/reference.service';
import {AnimalService} from '../../shared/services/animal.service';

@Component({
    selector: 'app-doc-check',
    templateUrl: './doc-check.component.html'
})
export class DocCheckComponent implements OnInit, OnDestroy {

    allowedFormats = [BarcodeFormat.QR_CODE];
    availableDevices: MediaDeviceInfo[];
    currentDeviceIndex: number;
    hasPermission: boolean;
    hasDevices: boolean;
    scannerEnabled = false;
    @ViewChild('scanner') scanner: ZXingScannerComponent;

    siteKey = ReCaptchaKey.siteKey;
    checkForm: FormGroup;
    scannerDialog = false;
    documentType: any;
    documentData1: any;
    documentData2: any;
    documentData3: any;
    animalTagNumberSel: any;
    animalData: any;

    constructor(public translate: TranslateService,
                private animalService: AnimalService,
                private anonymousService: AnonymousService,
                private reCaptchaV3Service: ReCaptchaV3Service,
                private activatedRoute: ActivatedRoute,
                private referenceService: ReferenceService,
                private router: Router) {
    }

    async ngOnInit() {
        this.checkForm = new FormGroup({
            docId: new FormControl(null, Validators.required)
        });

        const docId = this.activatedRoute.snapshot.queryParams.code;
        if (docId) {
            this.checkForm.setValue({docId});
            await this.getDocumentData(docId);
            

        }
    }
    showScanner() {
        this.scannerDialog = true;
        this.scannerEnabled = true;
        this.selectCamera();
    }
    hideScanner() {
        this.scannerDialog = false;
        this.scannerEnabled = false;
    }
    selectCamera() {
        if (this.hasPermission) {
            this.scanner.device = this.availableDevices[0];
            for (let i = 0; i < this.availableDevices.length; i++) {
                if (this.availableDevices[i].label.includes('back') || this.availableDevices[i].label.includes('Back')) {
                    this.scanner.device = this.availableDevices[i];
                    this.currentDeviceIndex = i;
                    break;
                }
            }
        }
    }
    switchCamera() {
        const newIndex = this.currentDeviceIndex + 1;
        if (newIndex < this.availableDevices.length) {
            this.scanner.device = this.availableDevices[newIndex];
            this.currentDeviceIndex = newIndex;
        } else {
            this.scanner.device = this.availableDevices[0];
            this.currentDeviceIndex = 0;
        }
    }
    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.availableDevices = [...devices];
        this.hasDevices = true;
        this.selectCamera();
    }
    onCamerasNotFound() {
        this.hasDevices = false;
    }
    scanSuccessHandler(docId: string) {
        this.checkForm.setValue({
            docId
        });
        this.hideScanner();
        this.onSubmit();
    }
    onHasPermission(has: boolean) {
        this.hasPermission = has;
    }

    async updateUrl(code: string) {
        await this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {code},
            queryParamsHandling: 'merge',
            skipLocationChange: false
        });
    }
    async getDocumentData(docId: string) {
        this.documentData1 = null;
        this.documentData2 = null;
        this.documentData3 = null;
        this.reCaptchaV3Service.execute(this.siteKey, 'AITSDocumentCheck', async (token) => {
            this.documentType = await this.anonymousService.docCheck(docId, token);
            const documentData = await this.anonymousService.getDocData(this.documentType.url);
            documentData.documentType = this.referenceService.getReference('documentType', this.documentType.documentTypeId);
            switch (this.documentType.documentTypeId) {
                case 1:
                    this.documentData1 = documentData;
                    break;
                case 2:
                    this.documentData2 = documentData;
                    break;
                case 3:
                    this.documentData3 = documentData;
                    if(this.documentData3){
                        if(this.documentData3.productParts[0]){
                            this.animalTagNumberSel=this.documentData3.productParts[0].tagNumber;        
                        }
                    }

                    this.animalData = await this.animalService.animalCheck2(this.animalTagNumberSel);
                    this.animalData.color = this.referenceService.getReference('color', this.animalData.colorId);
                    this.animalData.breed = this.referenceService.getReference('breed', this.animalData.breedId);
                    this.animalData.specie = this.referenceService.getReference('species', this.animalData.breed.speciesId);
                    this.animalData.status = this.referenceService.getReference('animalStatus', this.animalData.animalStatusId);
                    this.animalData.healthStatus = this.referenceService.getReference('animalHealthStatus', this.animalData.animalHealthStatusId);
                    // tslint:disable-next-line:max-line-length
                    this.animalData.clarifyingStatus = this.referenceService.getReference('animalClarifyingStatus', this.animalData.animalClarifyingStatusId);
                    this.animalData.owners.forEach(owner => {
                        owner.childRegion = this.getRegionById(owner.regionId);
                        owner.parentRegion = this.getRegionByLevel(owner.childRegion.parentId, 4);
                    });
                    // tslint:disable-next-line:triple-equals
                    if (this.animalData.animalStatusId == 1) {
                        this.animalData.age = this.calcAge(this.animalData.birthDate);
                    }

                    break;
            }
        }, {
            useGlobalDomain: false
        });
    }
    async onSubmit() {
        const docId = this.checkForm.value.docId.toUpperCase();
        await this.updateUrl(docId);
        this.getDocumentData(docId);
    }
    ngOnDestroy() {
        this.scannerEnabled = false;
    }

    getRegionById(id: string) {
        return this.referenceService.getReference('region', id);
    }

    getRegionByLevel(id: string, level: number) {
        let region = this.getRegionById(id);
        while (region.regionLevel > level) {
            region = this.getRegionById(region.parentId);
        }
        return region;
    }

    calcAge(birthDateStr: string) {
        const birthDate = new Date(birthDateStr);
        const now = new Date();
        const months = (now.getFullYear() * 12 + now.getMonth()) - (birthDate.getFullYear() * 12 + birthDate.getMonth());
        return this.referenceService.getAgeFromMonth(months);
    }
}
